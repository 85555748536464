import { 
    GET_ARTICLES, 
    GET_ARTICLE, 
    ADD_ARTICLE, 
    UPDATE_ARTICLE,
    GET_CATEGORIES,
    ADD_CATEGORY,
    UPDATE_CATEGORY 
} from '../actions/articles';


export default function projectReducer(state = [], { type, payload }) {
    switch (type) {
        case GET_ARTICLES:
            return {
                ...state,
                list: payload
            };
        case GET_ARTICLE:
            return {
                ...state,
                item: payload
            };
        case ADD_ARTICLE:
            return {
                ...state,
                item: payload
            };
        case UPDATE_ARTICLE:
            return {
                ...state,
                item: payload
            };
        case GET_CATEGORIES:
            return {
                ...state,
                categories: payload
            };
        case ADD_CATEGORY:
            return {
                ...state,
                item: payload
            };
        case UPDATE_CATEGORY:
            return {
                ...state,
                item: payload
            };
        default:
            return state;
    }
}