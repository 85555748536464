import { LOGIN_STATUS, SET_ACCESS_TOKEN } from '../actions/login';


export default function loginReducer(state = '', { type, payload }) {
	switch(type){
        case LOGIN_STATUS:
			return {
				...state,
				login: payload
			};
		case SET_ACCESS_TOKEN:
			return {
				...state,
				token: payload
			};
		default:
			return state;
	}
}