import { API } from '../api';
import { SET } from '../localstorage';
import { toast } from 'react-toastify';



export const LOGIN_STATUS = 'LOGIN_STATUS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const SET_USER_DATA = 'SET_USER_DATA';
  
export function login(logindata) {
	return dispatch => {
		API('POST', 'auth/admin/login', logindata, (serviceData) => {
			if (serviceData.data.success){
				SET('token', serviceData.data.result.token);
				SET('admin', JSON.stringify(serviceData.data.result.user.original));
				window.location.href ='/dashboard';
			}else{
				toast.error(serviceData.data.message);
			}
		});
	}
}