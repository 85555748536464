import { API } from '../api';

export const GET_SLIDERS = 'GET_SLIDERS';
export const ADD_SLIDER = 'ADD_SLIDER';

export function getSliders() {
	const data = null;
	return dispatch => {
		API('GET', 'slider-list', data, (result) => {
			dispatch({
				type: GET_SLIDERS,
				payload: result.data
			});
		}); 
	}; 
}

export function addSliders(data) { 
	return dispatch => {
		API('POST', 'add-slider', data, (result) => {
			dispatch({
				type: ADD_SLIDER,
				payload: result.banner
			});
		});
	};
}