import { combineReducers } from 'redux';
import users  from './users';
import login from './login';
import banners from './banners';
import references from './references';
import projects from './projects';
import articles from './articles';
import galleryImages from './gallery-images';
import galleries from './galleries';

import sliderImages from './slider-images';
import sliders from './sliders';

export default combineReducers({
    users,
    login,
    banners,
    references,
    projects,
    articles,
    galleryImages,
    galleries,
    sliderImages,
    sliders
});