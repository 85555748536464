import React from 'react';
import Loadable from 'react-loadable'

import DefaultLayout from './containers/DefaultLayout';

function Loading() {
  return <div>Yükleniyor...</div>;
}

const Breadcrumbs = Loadable({
  loader: () => import('./views/Base/Breadcrumbs'),
  loading: Loading,
});

const Cards = Loadable({
  loader: () => import('./views/Base/Cards'),
  loading: Loading,
});

const Carousels = Loadable({
  loader: () => import('./views/Base/Carousels'),
  loading: Loading,
});

const Collapses = Loadable({
  loader: () => import('./views/Base/Collapses'),
  loading: Loading,
});

const Dropdowns = Loadable({
  loader: () => import('./views/Base/Dropdowns'),
  loading: Loading,
});

const Forms = Loadable({
  loader: () => import('./views/Base/Forms'),
  loading: Loading,
});

const Jumbotrons = Loadable({
  loader: () => import('./views/Base/Jumbotrons'),
  loading: Loading,
});

const ListGroups = Loadable({
  loader: () => import('./views/Base/ListGroups'),
  loading: Loading,
});

const Navbars = Loadable({
  loader: () => import('./views/Base/Navbars'),
  loading: Loading,
});

const Navs = Loadable({
  loader: () => import('./views/Base/Navs'),
  loading: Loading,
});

const Paginations = Loadable({
  loader: () => import('./views/Base/Paginations'),
  loading: Loading,
});

const Popovers = Loadable({
  loader: () => import('./views/Base/Popovers'),
  loading: Loading,
});

const ProgressBar = Loadable({
  loader: () => import('./views/Base/ProgressBar'),
  loading: Loading,
});

const Switches = Loadable({
  loader: () => import('./views/Base/Switches'),
  loading: Loading,
});

const Tables = Loadable({
  loader: () => import('./views/Base/Tables'),
  loading: Loading,
});

const Tabs = Loadable({
  loader: () => import('./views/Base/Tabs'),
  loading: Loading,
});

const Tooltips = Loadable({
  loader: () => import('./views/Base/Tooltips'),
  loading: Loading,
});

const BrandButtons = Loadable({
  loader: () => import('./views/Buttons/BrandButtons'),
  loading: Loading,
});

const ButtonDropdowns = Loadable({
  loader: () => import('./views/Buttons/ButtonDropdowns'),
  loading: Loading,
});

const ButtonGroups = Loadable({
  loader: () => import('./views/Buttons/ButtonGroups'),
  loading: Loading,
});

const Buttons = Loadable({
  loader: () => import('./views/Buttons/Buttons'),
  loading: Loading,
});

const Charts = Loadable({
  loader: () => import('./views/Charts'),
  loading: Loading,
});

const Dashboard = Loadable({
  loader: () => import('./views/Dashboard'),
  loading: Loading,
});

const CoreUIIcons = Loadable({
  loader: () => import('./views/Icons/CoreUIIcons'),
  loading: Loading,
});

const Flags = Loadable({
  loader: () => import('./views/Icons/Flags'),
  loading: Loading,
});

const FontAwesome = Loadable({
  loader: () => import('./views/Icons/FontAwesome'),
  loading: Loading,
});

const SimpleLineIcons = Loadable({
  loader: () => import('./views/Icons/SimpleLineIcons'),
  loading: Loading,
});

const Alerts = Loadable({
  loader: () => import('./views/Notifications/Alerts'),
  loading: Loading,
});

const Badges = Loadable({
  loader: () => import('./views/Notifications/Badges'),
  loading: Loading,
});

const Modals = Loadable({
  loader: () => import('./views/Notifications/Modals'),
  loading: Loading,
});

const Colors = Loadable({
  loader: () => import('./views/Theme/Colors'),
  loading: Loading,
});

const Typography = Loadable({
  loader: () => import('./views/Theme/Typography'),
  loading: Loading,
});

const Widgets = Loadable({
  loader: () => import('./views/Widgets/Widgets'),
  loading: Loading,
});
/*
const Users = Loadable({
  loader: () => import('./views/Users/Users'),
  loading: Loading,
});
*/
const User = Loadable({
  loader: () => import('./views/Users/User'),
  loading: Loading,
});


const Users = Loadable({
  loader: () => import('./pages/Users'),
  loading: Loading,
});
const UsersList = Loadable({
  loader: () => import('./pages/Users/list'),
  loading: Loading,
});

const AddUser = Loadable({
  loader: () => import('./pages/Users/add'),
  loading: Loading,
});

const UpdateUser = Loadable({
  loader: () => import('./pages/Users/update'),
  loading: Loading,
});


const Articles = Loadable({
  loader: () => import('./pages/Articles'),
  loading: Loading,
});

const AddArticle = Loadable({
  loader: () => import('./pages/Articles/add'),
  loading: Loading,
});

const UpdateArticle = Loadable({
  loader: () => import('./pages/Articles/update'),
  loading: Loading,
});

const ArticlesList = Loadable({
  loader: () => import('./pages/Articles/list'),
  loading: Loading,
});

const Categories = Loadable({
  loader: () => import('./pages/Articles/categories'),
  loading: Loading,
});

const Menus = Loadable({
  loader: () => import('./pages/Menu'),
  loading: Loading,
});



const Banners = Loadable({
  loader: () => import('./pages/Banners'),
  loading: Loading,
});


const Galleries = Loadable({
  loader: () => import('./pages/ImageGallery'),
  loading: Loading,
});

const AddGallery = Loadable({
  loader: () => import('./pages/ImageGallery/add'),
  loading: Loading,
});

const GalleryList = Loadable({
  loader: () => import('./pages/ImageGallery/list'),
  loading: Loading,
});

const AddImages = Loadable({
  loader: () => import('./pages/ImageGallery/images'),
  loading: Loading,
});



const Sliders = Loadable({
  loader: () => import('./pages/Slider'),
  loading: Loading,
});

const AddSlider = Loadable({
  loader: () => import('./pages/Slider/add'),
  loading: Loading,
});

const SliderList = Loadable({
  loader: () => import('./pages/Slider/list'),
  loading: Loading,
});

const AddSliderImage = Loadable({
  loader: () => import('./pages/Slider/images'),
  loading: Loading,
});






const Projects = Loadable({
  loader: () => import('./pages/Projects'),
  loading: Loading,
});

const AddProject = Loadable({
  loader: () => import('./pages/Projects/add'),
  loading: Loading,
});

const UpdateProject = Loadable({
  loader: () => import('./pages/Projects/update'),
  loading: Loading,
});

const ProjectList = Loadable({
  loader: () => import('./pages/Projects/list'),
  loading: Loading,
});

const References = Loadable({
  loader: () => import('./pages/References'),
  loading: Loading,
});




// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config

const routes = [
  { path: '/', exact: true, name: 'Anasayfa', component: DefaultLayout },
  { path: '/dashboard', exact: true, name: 'Dashboard', component: Dashboard },

  { path: '/users', exact: true, name: 'Kullanıcılar', component: Users },
  { path: '/users/list', exact: true, name: 'Kullanıcı List', component: UsersList },
  { path: '/users/add', exact: true, name: 'Kullanıcı Ekle', component: AddUser },
  { path: '/users/update', exact: true, name: 'Kullanıcı Güncelle', component: UpdateUser },

  
  { path: '/articles', exact: true, name: 'Sayfalar', component: Articles },
  { path: '/articles/list', exact: true, name: 'Sayfa Listesi', component: ArticlesList },
  { path: '/articles/add', exact: true, name: 'Sayfa Ekle', component: AddArticle },
  { path: '/articles/update/:id', exact: true, name: 'Sayfa Güncelle', component: UpdateArticle },

  { path: '/articles/categories', exact: true, name: 'Kategoriler', component: Categories },
    
  { path: '/references/:id*', exact: true, name: 'Referanslar', component: References },

  { path: '/banners', exact: true, name: 'Banners', component: Banners },


  { path: '/galleries', exact: true, name: 'Galeri', component: Galleries },
  { path: '/gallery/list', exact: true, name: 'Galeri Listesi', component: GalleryList },
  { path: '/gallery/add', exact: true, name: 'Galeri Ekle', component: AddGallery },
  { path: '/gallery/images/:id', exact: true, name: 'Görsel Ekle', component: AddImages },


  { path: '/sliders', exact: true, name: 'Slider', component: Sliders },
  { path: '/slider/list', exact: true, name: 'Slider Listesi', component: SliderList },
  { path: '/slider/add', exact: true, name: 'Slider Ekle', component: AddSlider },
  { path: '/slider/images/:id', exact: true, name: 'Slider Ekle', component: AddSliderImage },



  { path: '/projects', exact: true, name: 'Projeler', component: Projects },
  { path: '/projects/list', exact: true, name: 'Projeler Listesi', component: ProjectList },
  { path: '/projects/add', exact: true, name: 'Proje Ekle', component: AddProject },
  { path: '/projects/update/:id*', exact: true, name: 'Proje Güncelle', component: UpdateProject },


  { path: '/menus', exact: true, name: 'Menus', component: Menus },


  { path: '/theme', exact: true, name: 'Theme', component: Colors },
  { path: '/theme/colors', name: 'Colors', component: Colors },
  { path: '/theme/typography', name: 'Typography', component: Typography },
  { path: '/base', exact: true, name: 'Base', component: Cards },
  { path: '/base/cards', name: 'Cards', component: Cards },
  { path: '/base/forms', name: 'Forms', component: Forms },
  { path: '/base/switches', name: 'Switches', component: Switches },
  { path: '/base/tables', name: 'Tables', component: Tables },
  { path: '/base/tabs', name: 'Tabs', component: Tabs },
  { path: '/base/breadcrumbs', name: 'Breadcrumbs', component: Breadcrumbs },
  { path: '/base/carousels', name: 'Carousel', component: Carousels },
  { path: '/base/collapses', name: 'Collapse', component: Collapses },
  { path: '/base/dropdowns', name: 'Dropdowns', component: Dropdowns },
  { path: '/base/jumbotrons', name: 'Jumbotrons', component: Jumbotrons },
  { path: '/base/list-groups', name: 'List Groups', component: ListGroups },
  { path: '/base/navbars', name: 'Navbars', component: Navbars },
  { path: '/base/navs', name: 'Navs', component: Navs },
  { path: '/base/paginations', name: 'Paginations', component: Paginations },
  { path: '/base/popovers', name: 'Popovers', component: Popovers },
  { path: '/base/progress-bar', name: 'Progress Bar', component: ProgressBar },
  { path: '/base/tooltips', name: 'Tooltips', component: Tooltips },
  { path: '/buttons', exact: true, name: 'Buttons', component: Buttons },
  { path: '/buttons/buttons', name: 'Buttons', component: Buttons },
  { path: '/buttons/button-dropdowns', name: 'Button Dropdowns', component: ButtonDropdowns },
  { path: '/buttons/button-groups', name: 'Button Groups', component: ButtonGroups },
  { path: '/buttons/brand-buttons', name: 'Brand Buttons', component: BrandButtons },
  { path: '/icons', exact: true, name: 'Icons', component: CoreUIIcons },
  { path: '/icons/coreui-icons', name: 'CoreUI Icons', component: CoreUIIcons },
  { path: '/icons/flags', name: 'Flags', component: Flags },
  { path: '/icons/font-awesome', name: 'Font Awesome', component: FontAwesome },
  { path: '/icons/simple-line-icons', name: 'Simple Line Icons', component: SimpleLineIcons },
  { path: '/notifications', exact: true, name: 'Notifications', component: Alerts },
  { path: '/notifications/alerts', name: 'Alerts', component: Alerts },
  { path: '/notifications/badges', name: 'Badges', component: Badges },
  { path: '/notifications/modals', name: 'Modals', component: Modals },
  { path: '/widgets', name: 'Widgets', component: Widgets },
  { path: '/charts', name: 'Charts', component: Charts },
  { path: '/users', exact: true,  name: 'Users', component: Users },
  { path: '/users/:id', exact: true, name: 'User Details', component: User },
];

export default routes;
