import { GET_PROJECTS, GET_PROJECT, UPDATE_PROJECT } from '../actions/projects';


export default function projectReducer(state = [], { type, payload }) {
	switch(type){
		case GET_PROJECTS:
            return {
				...state,
				list: payload
			};
		case GET_PROJECT:
			return {
				...state,
				item: payload
			}; 
			case UPDATE_PROJECT:
			return {
				...state,
				item: payload
			};
		default:
			return state;
	}
}