
/*
// export const API_BASE ='http://polatpeyzaj.mylocalserver.com/api';
// export const SITE_URL = 'http://polatpeyzaj.mylocalserver.com/';
// export const PUBLICUPLOAD = SITE_URL +'public/uploads';
*/

export const API_BASE ='http://www.polatpeyzaj.com/api';
export const SITE_URL = 'http://www.polatpeyzaj.com/';
export const PUBLICUPLOAD = SITE_URL +'public/uploads';
