import { GET_SLIDERS } from '../actions/sliders';


export default function slidersReducer(state = [], { type, payload }) {
	switch(type){
		case GET_SLIDERS:
            return {
				...state,
				list: payload
			};
		default:
			return state;
	}
}