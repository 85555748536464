import React, { Component } from 'react';


import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';

import { connect } from 'react-redux';
import { login } from '../../actions/login';
import { setTimeout } from 'timers';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = { 
        email: '',
        password: ''
    };
 
    
    this.loginSubmit = this.loginSubmit.bind(this);
     
  }

  loginSubmit(event) {
    event.preventDefault();
    this.setState({
      email: event.target.elements.txtEmail.value,
      password: event.target.elements.txtPassword.value 
    });
    setTimeout(() => {
      this.props.login(this.state, (data) =>{
        
      });
    }, 10);
    
   

    return false;
  }
  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="5">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <Form type="POST" onSubmit={this.loginSubmit}> 
                      <h1>Login</h1>
                      <p className="text-muted">Sign In to your account</p>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="text" id="txtEmail" name="txtEmail" placeholder="E-Posta" />
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="password" name="txtPassword" placeholder="Şifre" id="txtPassword" />
                      </InputGroup>
                      <Row>
                        <Col xs="6">
                          <Button color="primary" className="px-4">Login</Button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
                 
              </CardGroup>
            </Col>
          </Row>
        </Container>
         <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    state
  };
}

const mapsDispatchToProps = {
  login
}

export default connect(mapStateToProps, mapsDispatchToProps)(Login);

