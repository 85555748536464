import { GET_BANNERS } from '../actions/banners';


export default function bannerReducer(state = [], { type, payload }) {
	switch(type){
		case GET_BANNERS:
            return {
				...state,
				list: payload
			};
		default:
			return state;
	}
}