import { API, API_UPLOAD } from '../api';


export const GET_SLIDERSIMAGES = 'GET_SLIDERSIMAGES';
export const ADD_SLIDERSIMAGES = 'ADD_SLIDERSIMAGES';

export function getSliderImages() {
	const data = null;
	return dispatch => {
		API('GET', 'list-slider-image', data, (result) => {
			dispatch({
				type: GET_SLIDERSIMAGES,
				payload: result.data
			});
		}); 
	}; 
}

export function addSliderImages(data) { 
 
	return dispatch => {
		API_UPLOAD('POST', 'add-slider-image', data, (result) => {
			dispatch({
				type: ADD_SLIDERSIMAGES,
				payload: result
			});
		});
	};
}