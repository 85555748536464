import axios from 'axios';
import { API_BASE } from '../config/evn';
import { GET } from '../localstorage';

import { toast } from 'react-toastify';


export function API(method, url, data, callback) {


    axios({
        method: method,
        url: `${API_BASE}/${url}`,
        data: data,
        headers: { token: GET('token') }
    })
        .then(result => {

            if (result.status === 200) {
                callback(result)
            } else if (result.status === 404) {

            } else if (result.status === 401) {

            } else if (result.status === 500) {

            } else if (result.status === 422) {

            }
        })
        .catch(error => {
            console.log('error')
        });
}

export function API_UPLOAD(method, url, data, callback) {

    const updata = new FormData();

    updata.append('file', data.imageFile, data.imageFile.name);
    updata.append('img_x', data.crop.x);
    updata.append('img_y', data.crop.y);
    updata.append('img_width', data.crop.width);
    updata.append('img_height', data.crop.height);
    updata.append('title', data.title);
    updata.append('description', data.description);
    updata.append('link', data.link);
    updata.append('galleryId', data.galleryId);
    updata.append('sliderId', data.sliderId);
    updata.append('type', data.type);


    axios({
        method: method,
        url: `${API_BASE}/${url}`,
        data: updata,
        headers: { token: GET('token') }
    })
        .then(result => {

            if (result.status === 200) {
                callback(result)
            } else if (result.status === 404) {

            } else if (result.status === 401) {

            } else if (result.status === 500) {

            } else if (result.status === 422) {

            }
        })
        .catch(error => {
            console.log('error')
        });
}

export function API_PROJECT(method, url, data, callback) {
    const updata = new FormData();

    try {

        if (data.imageFile) {
            updata.append('file', data.imageFile, data.imageFile.name);
            updata.append('img_x', data.crop.x);
            updata.append('img_y', data.crop.y);
            updata.append('img_width', data.crop.width);
            updata.append('img_height', data.crop.height);
        }
        if (typeof (data.content) != "object") {
            updata.append('content', data.content);
        }
        updata.append('id', data.id);
        updata.append('name', data.name);
        updata.append('url', data.url);
        updata.append('title', data.title);
        updata.append('description', data.description);
        updata.append('gallery_images_id', data.gallery_images_id.value);
        updata.append('slider_images_id', data.slider_images_id.value);
        updata.append('video', data.video);
        updata.append('type', data.type);
        updata.append('location', data.location);
        updata.append('employer', data.employer);
        updata.append('state', data.state);
        updata.append('status', data.status);

        axios({
            method: method,
            url: `${API_BASE}/${url}`,
            data: updata,
            headers: { token: GET('token') }
        }).then(result => {

                if (result.status === 200) {
                    callback(result)
                } else if (result.status === 404) {

                } else if (result.status === 401) {

                } else if (result.status === 500) {

                } else if (result.status === 422) {

                }
            })
            .catch(error => {
                console.log('error')
            });
    } catch (error) {

    }
}

export function API_ARTICLE(method, url, data) {
    const updata = new FormData();

    try {
        if (data.imageFile) {
            updata.append('file', data.imageFile, data.imageFile.name);
            updata.append('img_x', data.crop.x);
            updata.append('img_y', data.crop.y);
            updata.append('img_width', data.crop.width);
            updata.append('img_height', data.crop.height);
        }
        
        if (data.heroImg) {
            updata.append('coverfile', data.heroImg, data.heroImg.name);
        }
        updata.append('id', data.id);
        updata.append('name', data.name);
        updata.append('title', data.title);
        updata.append('url', data.url);
        updata.append('description', data.description);
        updata.append('shortContent', data.shortContent);
        updata.append('content', data.content);
        updata.append('categoryId', data.categoryId.value);
        updata.append('sliderId', data.sliderId.value);
        updata.append('sort', data.sort);
        updata.append('status', data.status);

        axios({
            method: method,
            url: `${API_BASE}/${url}`,
            data: updata,
            headers: { token: GET('token') }
        }).then(result => {
         
            if (result.status === 200) {
                console.log(result);
                toast.error('sss');
                
                
            } else if (result.status === 404) {

            } else if (result.status === 401) {

            } else if (result.status === 500) {

            } else if (result.status === 422) {

            }
        })
            .catch(error => {
                console.log('error')
            });
    } catch (error) {

    }
}