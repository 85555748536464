import { API, API_ARTICLE } from '../api';

export const GET_ARTICLES = 'GET_ARTICLES';
export const GET_ARTICLE = 'GET_ARTICLE';
export const ADD_ARTICLE = 'ADD_ARTICLE';
export const UPDATE_ARTICLE = 'UPDATE_ARTICLE';

export const GET_CATEGORIES = 'GET_CATEGORIES';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';


export function getArticles() {
	const data = null;
	return dispatch => {
		API('GET', 'article-list', data, (result) => {
			dispatch({
				type: GET_ARTICLES,
				payload: result.data
			});
		}); 
	}; 
}
export function getArticle(id) {
	const data = null;
	return dispatch => {
		API('GET', 'article/'+ id, data, (result) => {
			dispatch({
				type: GET_ARTICLE,
				payload: result.data[0]
			});
		}); 
	}; 
}
export function addArticle(data) {
	return dispatch => {
		API_ARTICLE('POST', 'add-article', data, (result) => {
			dispatch({
                type: ADD_ARTICLE,
				payload: result
			});
		});
	};
}
export function updateArticle(data) {
	return dispatch => {
		API_ARTICLE('POST', 'update-article', data, (result) => {
			dispatch({
                type: UPDATE_ARTICLE,
				payload: result
			});
		});
	};
}

export function addCategory(data) {
	return dispatch => {
		API('POST', 'add-category', data, (result) => {
			dispatch({
				type: ADD_CATEGORY,
				payload: result
			});
		});
	};
}

export function getCategories() {
	const data = null;
	return dispatch => {
		API('GET', 'category-list', data, (result) => {
			dispatch({
				type: GET_CATEGORIES,
				payload: result.data
			});
		});
	};
}