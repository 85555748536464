import { API, API_UPLOAD } from '../api';


export const GET_GALLERYIMAGES = 'GET_GALLERYIMAGES';
export const ADD_GALLERYIMAGES = 'ADD_GALLERYIMAGES';

export function getGalleryImages() {
	const data = null;
	return dispatch => {
		API('GET', 'list-gallery-image', data, (result) => {
			dispatch({
				type: GET_GALLERYIMAGES,
				payload: result.data
			});
		}); 
	}; 
}

export function addGalleryImages(data) { 
 
	return dispatch => {
		API_UPLOAD('POST', 'add-gallery-image', data, (result) => {
			dispatch({
				type: ADD_GALLERYIMAGES,
				payload: result
			});
		});
	};
}