import { API, API_UPLOAD } from '../api';



export const GET_REFERENCES = 'GET_REFERENCES';
export const GET_REFERENCE = 'GET_REFERENCE';
export const ADD_REFERENCE = 'ADD_REFERENCE';

export function getReferences() {
	const data = null;
	return dispatch => {
		API('GET', 'references-list', data, (result) => {
			dispatch({
				type: GET_REFERENCES,
				payload: result.data
			});
		}); 
	}; 
}

export function getReference(id) {
	const data = null;
	return dispatch => {
		API('GET', 'reference/'+id, data, (result) => {
			dispatch({
				type: GET_REFERENCE,
				payload: result.data[0]
			});
		});
	};
}

export function addReference(data) {
	return dispatch => {
		API_UPLOAD('POST', 'add-reference', data, (result) => {
			dispatch({
				type: ADD_REFERENCE,
				payload: result.user
			});
		});
	};
}