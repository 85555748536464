function SET(key,data) {
    sessionStorage.setItem(key, data);
}
function GET(key) {
    return sessionStorage.getItem(key);
}

export {
    SET,
    GET
}