import { toast } from 'react-toastify';

import { API, API_PROJECT } from '../api';

export const GET_PROJECTS = 'GET_PROJECTS';
export const GET_PROJECT = 'GET_PROJECT';
export const ADD_PROJECT = 'ADD_PROJECT';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';

export function getProjects() {
	const data = null;
	return dispatch => {
		API('GET', 'projects-list', data, (result) => {
			dispatch({
				type: GET_PROJECTS,
				payload: result.data
			});
		});
	};
}

export function getProject(id) {
	const data = null;
	return dispatch => {
		API('GET', 'project/' + id, data, (result) => {
			dispatch({
				type: GET_PROJECT,
				payload: result.data[0]
			});
		});
	};
}

export function addProject(data) {
	return dispatch => {
		API_PROJECT('POST', 'add-project', data, (result) => {

			dispatch({
				type: ADD_PROJECT,
				payload: result
			});
		});
	};
}

export function updateProject(data) {
	return dispatch => {
		API_PROJECT('POST', 'update-project', data, (result) => {
			console.log();
			if (result.data.status) {
				toast.error(result.data.status);
				setTimeout(() => {
					window.location.href = '/projects/list';
				}, 1000);
			}

		});
	};
}