import React, { Component } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';


import './App.scss';


// Containers
import { DefaultLayout } from './containers';
// Pages
import { Page404, Page500, Register } from './views/Pages';
import Login from './pages/Login';





// import { renderRoutes } from 'react-router-config';

class App extends Component {
  render() {
    return (

      <BrowserRouter>
        <Switch>
          <Route exact path="/login" name="Login Page" component={Login} />
          <Route exact path="/register" name="Register Page" component={Register} />
          <Route exact path="/404" name="Page 404" component={Page404} />
          <Route exact path="/500" name="Page 500" component={Page500} />
          <Route path="/" name="Home" component={DefaultLayout} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
