import { GET_REFERENCES, GET_REFERENCE } from '../actions/references';


export default function referenceReducer(state = [], { type, payload }) {
	switch(type){
		case GET_REFERENCES:
            return {
				...state,
				list: payload
			};
		case GET_REFERENCE:
			return {
				...state,
				item: payload
			};
		default:
			return state;
	}
}