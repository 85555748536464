import { API, API_UPLOAD } from '../api';

export const GET_BANNERS = 'GET_BANNERS';
export const ADD_BANNERS = 'ADD_BANNERS';

export function getBanners() {
	const data = null;
	return dispatch => {
		API('GET', 'banner-list', data, (result) => {
			dispatch({
				type: GET_BANNERS,
				payload: result.data
			});
		}); 
	}; 
}

export function addBanner(data) { 
	return dispatch => {
		API_UPLOAD('POST', 'add-banner', data, (result) => {
			dispatch({
				type: ADD_BANNERS,
				payload: result.banner
			});
		});
	};
}