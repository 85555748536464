import { API } from '../api';

export const GET_GALLERIES = 'GET_GALLERIES';
export const ADD_GALLERY = 'ADD_GALLERY';

export function getGalleries() {
	const data = null;
	return dispatch => {
		API('GET', 'gallery-list', data, (result) => {
			dispatch({
				type: GET_GALLERIES,
				payload: result.data
			});
		}); 
	}; 
}

export function addGalleries(data) { 
	return dispatch => {
		API('POST', 'add-gallery', data, (result) => {
			dispatch({
				type: ADD_GALLERY,
				payload: result.banner
			});
		});
	};
}